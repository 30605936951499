import Img from 'gatsby-image';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import { DefaultLayout } from '../../components/layout';
import { CaseBlock, Facts, Stage } from '../../components/molecules';
import { Button } from '../../components/atoms';

import './deepconnect.scss';

const deepconnectQuery = graphql`
  {
    allStagesJson(filter: { siteTitle: { eq: "deepconnect" } }) {
      edges {
        node {
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            childImageSharp {
              fluid(maxWidth: 800, quality: 92) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageAlt
        }
      }
    }
    allImageSharp(filter: { fluid: { originalName: { regex: "/(anleitung|drag|overview)/" } } }) {
      edges {
        node {
          id
          fluid(maxWidth: 800, quality: 92) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

const DeepconnectCase = () => (
  <StaticQuery
    query={deepconnectQuery}
    render={({ allStagesJson, allImageSharp }) => {
      const stageData = allStagesJson.edges[0].node;
      const classImg = allImageSharp.edges[3].node.fluid;
      const meetupImg = allImageSharp.edges[1].node.fluid;

      return (
        <DefaultLayout>
          <Stage
            modifiers={['gradient', 'case']}
            image={{
              fluid: stageData.imageSrc.childImageSharp.fluid,
              alt: stageData.imageAlt,
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: stageData.title }} />}
          >
            <div>
              {stageData.contentBlocks.map((block) => (
                <p key={block.id} dangerouslySetInnerHTML={{ __html: block.value }} />
              ))}
            </div>
            <div className="download__button">
              {<Button url={'https://deepconnect.deepreturn.com'} text={`Download`} isPrimary />}
            </div>
          </Stage>

          <CaseBlock
            title="Zeitersparnis durch Logik"
            subtitle="Dateien automatisiert manipulieren"
            graphic={<Img fluid={classImg} alt="" />}
          >
            <p>
              DeepConnect ist immer dann hilfreich, wenn Sie Formatierungen an einer CSV oder XML Datei vornehmen wollen. Ein
              typisches Beispiel sind Dateien, die automatisch von einem Shop-Server erzeugt werden. Diese Dateien befindet
              sich in der Regel nicht in der gewünschten Struktur, um sie in der Zielsoftware (z.B. ERP-System) einfügen zu
              können. Hier stellt sich nun die Frage: Wie bringe ich diese Datei in das gewünschte Struktur, damit sie von
              meiner Buchhaltungssoftware erkannt werden können? Eine Möglichkeit wäre es jede Datei einzeln zu öffnen, die
              nötigen Formatierungen vorzunehmen, zu speichern und anschliessend in die Buchhaltungssoftware einzulesen.
              Dieses Vorgehen ist indessen äusserst zeitintensiv! Anstatt jede Datei herunterzuladen und mühsam die nötigen
              Formatierungen an jeder Datei einzeln vorzunehmen, können Sie mit DeepConnect anhand einer Struktur-Datei
              einmalig eine Logik bauen, welche dann wiederum auf unbeschränkt viele weitere Dateien angewendet werden kann.
            </p>
          </CaseBlock>

          <CaseBlock
            title="Hier zum Download"
            subtitle="Erfahren Sie mehr über Preise und unser Angebot"
            graphic={<Img fluid={meetupImg} alt="Preise" />}
          >
            <p>
              {/* added this file to .eslintignore because of the weird space problems with the link */}
              Wir vergleichen die Fähigkeit eines Programmierers gerne überspitzt mit einer gewissen “Superkraft”. Mithilfe
              von Konstrukten können Programmierer einfach und effizient Dateien formatieren. Es gibt allerdings eine enorme
              Eintrittshürde, die es vielen Menschen verunmöglicht ihre Abläufe selbständig automatisieren zu können. Vor
              diesem Hintergrund entstand die Idee für die Software DeepConnect. Anhand von einer einfachen
              Benutzeroberfläche hilft Ihnen DeepConnect dabei, strukturierte Daten automatisiert zu formatieren. Wir wollen
              es auch Personen, die (noch) keine Programmiererfahrungen haben, ermöglichen, Automatisierungen zu bauen und im
              Arbeitsalltag intelligent zu integrieren.{' '}
            </p>
          </CaseBlock>

          <div className="container">
            <Facts title="Technologie">
              <ul>
                <li>Einfache Bedienung durch Drag&Drop</li>
                <li>Die Ordnerüberwachung ermöglicht automatisierten Ablauf</li>
                <li>Zeitersparnis durch einmalige Umsetzung</li>
                <li>Dateien werden lokal konvertiert</li>
              </ul>
            </Facts>
          </div>
        </DefaultLayout>
      );
    }}
  />
);

export default DeepconnectCase;
